import Base, { ModelAPIIndexMeta, ModelAPIIndexParams } from './Base';
import Variant, { VariantAttrs } from './Variant';
import Wishlist from './Wishlist';

interface ProductImageAttrs {
  image: string;
  caption?: string;
}

export interface ProductAttrs {
  id: number;
  name: string;
  slug: string;
  images: ProductImageAttrs[];
  description: string;
  coins: number;
  metaTitle?: string;
  metaDescription?: string;
  createdAt: string;
  updatedAt: string;
  Variants?: VariantAttrs[];
  orderItemCount?: number;
  personWishlist?: boolean;
}

export class ProductImage extends Base<ProductImageAttrs> {
  getThumbnailURL(width: number, height?: number) {
    return imageUtil.getThumbnailURL(this.data.image, width, height);
  }
}

export default class Product extends Base<ProductAttrs> {
  static async fetch(params?: Partial<ProductAttrs> & ModelAPIIndexParams) {
    const response = await this.$api.get<{
      data: ProductAttrs[];
      meta: ModelAPIIndexMeta;
    }>('v1/products', { params });
    const data = response.data.data;
    const meta = response.data.meta;

    return { data, meta };
  }

  static async fetchBySlug(slug: string) {
    const { data } = await this.fetch({ slug });
    return data[0];
  }

  images$: ProductImage[];
  variants$?: Variant[];

  constructor(attrs: ProductAttrs) {
    super(attrs);

    this.images$ = attrs.images.map((image) => new ProductImage(image));
    this.variants$ = attrs.Variants?.map((variant) => new Variant(variant));
  }

  get theCoins() {
    return new Intl.NumberFormat().format(this.data.coins);
  }

  get routePath() {
    return `/shvrcoin/${this.data.slug}`;
  }

  getThumbnailURL(width: number, height?: number) {
    return imageUtil.getThumbnailURL(this.data.images[0].image, width, height);
  }

  wishlist() {
    return this.data.personWishlist
      ? Wishlist.$api.delete(`/v1/wishlists/${this.data.id}`)
      : Wishlist.$api.post('/v1/wishlists', { productId: this.data.id });
  }
}
