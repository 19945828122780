import Base, { ModelAPIIndexMeta, ModelAPIIndexParams } from './Base';
import Product, { ProductAttrs } from './Product';

export interface WishlistAttrs {
  productId: number;
  personId: number;
  createdAt: string;
  updatedAt: string;
  Product: ProductAttrs;
}

export default class Wishlist extends Base<WishlistAttrs> {
  static async fetch(params?: Partial<WishlistAttrs> & ModelAPIIndexParams) {
    const response = await this.$api.get<{
      data: WishlistAttrs[];
      meta: ModelAPIIndexMeta;
    }>('v1/wishlists', { params });
    const data = response.data.data;
    const meta = response.data.meta;

    return { data, meta };
  }

  product$: Product;

  constructor(attrs: WishlistAttrs) {
    super(attrs);

    this.product$ = new Product(attrs.Product);
  }

  remove() {
    return Wishlist.$api.delete(`/v1/wishlists/${this.data.productId}`);
  }
}
