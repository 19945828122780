import Base from './Base';

export interface VariantAttrs {
  id: number;
  productId: number;
  stock: number;
  createdAt: string;
  updatedAt: string;
  AttributeValues: {
    id: number;
    attributeId: number;
    value: string;
    createdAt: string;
    updatedAt: string;
    Attribute: {
      id: number;
      name: string;
      createdAt: string;
      updatedAt: string;
    };
    VariantAttributeValues: {
      variantId: number;
      attributeValueId: number;
      createdAt: string;
      updatedAt: string;
    };
  }[];
}

export default class Variant extends Base<VariantAttrs> {
  addToCart() {
    return Variant.$api.post('/v1/carts', {
      variantId: this.data.id,
      quantity: 1,
    });
  }
}
